*{
    
    font-family: 'Kanit', sans-serif !important;
    scroll-behavior: smooth !important;
}
body{
overflow-x: hidden !important;
    font-family: 'Kanit', sans-serif !important;
}
.bacofloD{
  background: rgb(69,82,121);
  background: linear-gradient(0deg, rgba(69,82,121,1) 0%, rgba(54,135,181,1) 46%, rgba(69,82,121,1) 86%);
 height: 100vh;
}
.btn {
    -webkit-border-radius: 13;
    -moz-border-radius: 13;
    border-radius: 13px;
    font-family: Arial;
    color: #ffffff !important;
    font-weight: 600;
    font-size: 20px;
    background-color: transparent !important;
    padding: 10px 20px 10px 20px;
    border: solid transparent 2px !important;
    text-decoration: none !important;
  }
  
  .btn:hover {
    background-color: transparent !important ;
    color: #fff !important;
    text-decoration: none !important;



    
  }


  nput:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
    font-size: 0.75em;
    color: #b9b9b9;
    top: -5px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;
}

.styled-input {
    float: left;
    width: 293px;
    margin: 1rem 0;
    position: relative;
    border-radius: 4px;
}

@media only screen and (max-width: 768px){
    .styled-input {
        width:100%;
    }
}

.styled-input label {
    color: #ffffff;
    padding: 1.3rem 30px 1rem 30px;
    position: absolute;
    top: 10px;
    left: 0;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    pointer-events: none;
}

.styled-input.wide { 
    width: 650px;
    max-width: 100%;
}
label{
    font-size: 10px;
}



@media screen and (min-width: 1000px) {
    label{
        font-size: 20px;
    }
}
input,
textarea {
    padding: 30px;
    border: 0;
    width: 100%;
    font-size: 0.8rem;
    background-color: #02005fd2;
    color: white;
    border-radius: 50px;
}

@media screen and (min-width: 1000px) {
    input,
textarea {
    padding: 30px;
    border: 0;
    width: 100%;
    font-size: 1rem;
    background-color:  #5e5e5e88 ;
    color: rgb(255, 255, 255);
    border-radius: 30px;
}

    
  }

input:focus,
textarea:focus { outline: 0; }

input:focus ~ span,
textarea:focus ~ span {
    width: 100%;
    -webkit-transition: all 0.075s ease;
    transition: all 0.075s ease;
}

textarea {
    width: 100%;
    min-height: 15em;
}

.input-container {
    width: 650px;
    max-width: 100%;
    margin: 30px auto 25px auto;
}

.submit-btn {
   
    padding: 14px 20px !important;
    border-radius: 20px;
    width: 30%;
    display: inline-block;
background-color: rgb(252, 250, 250);
    text-transform: uppercase;
    transition: 0.5s;
    color: rgb(0, 0, 0);
    font-size: 18px;
    cursor: pointer;
 border: none !important;
    -webkit-transition: all 300ms ease;

    justify-content: center;
text-align: center;
align-items: center;

}

.submit-btn:hover {
    transform: translateY(1px);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10),
              0 1px 1px 0 rgba(0,0,0,0.09);
}

@media (max-width: 768px) {
    .submit-btn {
        width:60%;
    
        float: none;
        text-align:center;
        font-size: 14px;
    }
}

input[type=checkbox] + label {
  color: #ccc;
  font-style: italic;
} 

input[type=checkbox]:checked + label {
  color: #f00;
  font-style: normal;
}


.sponser_title_text{
    font-size: 50px;
    text-shadow: 1px 1px 1px #000000, 
    3px 3px 5px rgb(25, 6, 0); 
}
@media screen and (min-width: 1000px) {
    .sponser_title_text{
        font-size: 130px;
        text-shadow: 1px 1px 1px #000000, 
        3px 3px 5px rgb(25, 6, 0); 
    }
  }
  
.sponser_under_text{
font-size: 10px;
}

@media screen and (min-width: 1000px) {
    .sponser_under_text{
        font-size:20px;
    }
    
  }




  .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    display: none !important;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 1rem !important;
}


.btn-primary:focus {
     box-shadow: 0 0 0 0.2rem transparent !important;
}
















.btn-grad {
    background-image: linear-gradient(to right, #ff4c85 0%, rgb(255, 212, 96)  51%, #f60853  100%);
    text-transform: uppercase;
    transition: 0.5s;
    margin: 10px;
    padding: 12px 40px;
    text-align: center;
 
 
    background-size: 200% auto;
    color: white; 
    font-size: 12px;           
    border-radius: 10px;
    display: block;
    width: 70%;
  }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
 
  @media screen and (min-width: 1000px) {
  


.btn-grad {
    background-image: linear-gradient(to right, #ff4c85 0%, rgb(255, 212, 96)  51%, #f60853  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    font-size: 19px !important;    
    background-size: 200% auto;
    color: white;            
    border-radius: 10px;
    display: block;
    width: 30%;
  }

  }
  


  .parahead1{
    font-size: 20px;
    align-items: center !important;
    text-align: center !important;
    color: #fff !important;
    font-weight: 600;

  }


  @media screen and (min-width: 1000px) {
    .parahead1{
        font-size: 40px;
        align-items: center !important;
        text-align: center !important;
        color: #fff !important;
        font-weight: 600;
    
      }
  }
  



  
  .underparahead1{
    font-size: 15px;
    align-items: center !important;
    text-align: center !important;
    color: #fff !important;
    font-weight: 300;

  }


  @media screen and (min-width: 1000px) {
    .underparahead1{
        font-size: 16px;
        align-items: center !important;
        text-align: center !important;
        color: #fff !important;
        font-weight: 400;
    
      }
  }
  

  .paddodpar{
 
   padding: 10px !important;
   border-radius: 10px !important;
   background-color: rgba(148, 148, 148, 0.273) !important;
   -webkit-backdrop-filter: blur(5px);
   backdrop-filter: blur(5px);
  }



  
  .parahead1part{
    font-size: 24px;
    align-items: center !important;
    text-align: center !important;
    color: #fff !important;
    font-weight: 600;
    text-shadow: 1px 1px 1px #000000, 
    3px 3px 5px rgb(25, 6, 0); 

  }


  @media screen and (min-width: 1000px) {
    .parahead1part{
        font-size: 90px;
        align-items: center !important;
        text-align: center !important;
        color: #fff !important;
        font-weight: 600;
    
      }
  }
  



  .centerpartner{
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
  }



  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    height: 10px;
    border-top: 1px solid rgb(255, 255, 255);
}













  


.maketextprominent{
    text-shadow: 1px 1px 1px #000000, 
    3px 3px 5px rgb(25, 6, 0); 
}




.centeritall{
  position: relative;
justify-content: center;
align-items: center;
text-align: center;
display: flex;
}




.pulse-image {
  animation: pulse 2s infinite;
  border-radius: 50%;
}


@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgba(4, 236, 233, 0.4);
  }
  5% {
      transform: scale(1.01);
  }
  15% {
      transform: scale(1);
  }
  70% {
      box-shadow: 0 0 0 50px rgba(255, 166, 32, 0);
  }
  100% {
      box-shadow: 0 0 0 100px rgba(20, 13, 217, 0);
  }
}


.zindexx{
  z-index: 10000000 !important;
}





.parahead1l{
  font-size: 23px;
  align-items: center !important;
  text-align: center !important;
  color: #fff !important;
  font-weight: 600;


}


@media screen and (min-width: 1000px) {
  .parahead1l{
      font-size: 50px;
      align-items: center !important;
      text-align: center !important;
      color: #fff !important;
      font-weight: 600;
    
  
    }
}












































.timeline{
  margin-top:20px;
  position:relative;
  
}

.timeline:before{
  position:absolute;
  content:'';
  width:4px;
  height:calc(100% + 50px);
background: rgba(255, 255, 255, 0.9);

  left:14px;
  top:5px;
  border-radius:4px;
}

.timeline-month{
  position:relative;
  padding:4px 15px 4px 35px;
  background-color:#ffffff;
  display:inline-block;
  width:auto;
  border-radius:40px;
  border:1px solid #0266c9;
  border-right-color:rgb(243, 142, 11);
  margin-bottom:30px;
}

.timeline-month span{
  position:absolute;
  top:-1px;
  left:calc(100% - 10px);
  z-index:-1;
  white-space:nowrap;
  display:inline-block;
  background-color:#c62d2d;
  padding:4px 10px 4px 20px;
  border-top-right-radius:40px;
  border-bottom-right-radius:40px;
  border:1px solid black;
  box-sizing:border-box;
}

.timeline-month:before{
  position:absolute;
  content:'';
  width:20px;
  height:20px;
background: rgb(176, 100, 253);
background: -moz-linear-gradient(top, rgba(138,145,150,1) 0%, rgba(122,130,136,1) 60%, rgba(112,120,125,1) 100%);
background: -webkit-linear-gradient(top, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(112,120,125,1) 100%);
background: linear-gradient(to bottom, rgba(138,145,150,1) 0%,rgba(122,130,136,1) 60%,rgba(112,120,125,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a9196', endColorstr='#70787d',GradientType=0 );
  border-radius:100%;
  border:1px solid #17191B;
  left:5px;
}

.timeline-section{
  padding-left:35px;
  display:block;
  position:relative;
  margin-bottom:30px;
}

.timeline-date{
  margin-bottom:15px;
  padding:2px 15px;
background-color: #78dfff8e;
  position:relative;
  display:inline-block;
  border-radius:20px;

  color:#fff;
text-shadow:1px 1px 1px rgb(255, 255, 255);
}
.timeline-section:before{
  content:'';
  position:absolute;
  width:30px;
  height:1px;
  background-color:#013d91;
  top:12px;
  left:20px;
}

.timeline-section:after{
  content:'';
  position:absolute;
  width:10px;
  height:10px;
background-color: #00ccc5;
  top:7px;
  left:11px;
  border:1px solid #17191B;
  border-radius:100%;
}

.timeline-section .col-sm-4{
  margin-bottom:15px;
}

.timeline-box{
  position:relative;
  
 background-color:#ffffffb0;
  border-radius:15px;
  border-top-left-radius:0px;
  border-bottom-right-radius:0px;
 
  transition:all 0.3s ease;
  overflow:hidden;
}

.box-icon{
  position:absolute;
  right:5px;
  top:0px;
}

.box-title{
  padding:5px 15px;
  border-bottom: 1px solid #17191B;
}

.box-title i{
  margin-right:5px;
}

.box-content{
  padding:5px 15px;
  background-color:#002346df;
}

.box-content strong{
  color:#ffffff;
  font-style:italic;
  margin-right:5px;
}

.box-item{
  margin-bottom:5px;
  color: #fff !important;

}

.box-footer{
 padding:5px 15px;

  background-color:#00206b79;
  text-align:start;
color: #fff;

}





.centeralldiv{

  text-align: center;
}










.color_title_spea{
  color: #00ccc5 !important;
}



































.btn-grady {
 background-color: #006381 !important;
  text-transform: uppercase;
  transition: 0.5s;
  margin: 13px;
  padding: 12px 20px;
  text-align: center;

  font-size: 10px !important;
  background-size: 200% auto;
  color: white; 
  font-size: 12px;           
  border-radius: 10px;
  display: block;
  width: 70%;
}

.btn-grady:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

@media screen and (min-width: 1000px) {



.btn-grady {
  background-color: #0ac6dedf !important;
  margin: 10px;
  padding: 15px 25px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  font-size: 19px !important;    
  background-size: 200% auto;
  color: white;            
  border-radius: 10px;
  display: block;
  width: 20%;

}

}




img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
