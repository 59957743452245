.desktop_hero_bg{
  background: rgb(69,82,121);
  background: -moz-linear-gradient(0deg, rgba(69,82,121,1) 0%, rgba(54,135,181,1) 46%, rgba(69,82,121,1) 86%);
  background: -webkit-linear-gradient(0deg, rgba(69,82,121,1) 0%, rgba(54,135,181,1) 46%, rgba(69,82,121,1) 86%);
  background: linear-gradient(0deg, rgba(69,82,121,1) 0%, rgba(54,135,181,1) 46%, rgba(69,82,121,1) 86%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#455279",endColorstr="#455279",GradientType=1);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
   }
   
   .flex{
       display: flex;
       align-items:center;
       justify-content: center;
     }