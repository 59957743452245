
.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 450px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }
.centerallspeaker{
    background-color: rgba(148, 148, 148, 0.273) !important;
    text-align: center;
    border-radius: 20px !important;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.speaker_head{
    font-size: 17px;
    font-weight: 700;
    color: #fff;
}


@media screen and (min-width: 1000px) {
    .speaker_head{
        font-size: 20px;
        font-weight: 700;
    }
    
  }
  



  
.speaker_para{
    font-size: 13px;
    font-weight: 400;
    color: #fff;
}


@media screen and (min-width: 1000px) {
    .speaker_para{
        font-size: 12px;
        font-weight: 300;
    }
    
  }
  